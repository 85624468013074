import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { handleGenInfo } from '../comps/toast'
import PowerCoinz from '../comps/payments/PowerCoinz'

const PowerCoinzScreen = () => {
    const { t } = useTranslation()

    useEffect(() => {
        handleGenInfo(t('coinz.please_wait'))
        // eslint-disable-next-line
    }, [])

    return (
        <div style={styles.main}>
            <PowerCoinz screen={true} />
        </div>
    )
}

const styles = {
    main: {
        textAlign: 'center',
        maxWidth: 600,
        margin: 'auto',
    }
}

export default PowerCoinzScreen