import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import WhatIsTxId from '../cart/WhatIsTxId'
import { getPlaceholderLabelShow } from '../cart/getPlaceholderLabelShow'
import Modal from '../Modal'
import { handleGenInfo } from '../toast'
import '../../styles/cart.css'
import { updateTransactionId } from '../../utils/sql/updateTransactionId'

const TransactionId = ({
    data,
    txId,
    setTxId = () => { },
    dark = false,
}) => {
    const { t, i18n } = useTranslation()

    const { pathname } = useLocation()
    const isCart = pathname === '/checkout' ||
        pathname === '/dvds'
        ? true
        : false

    const {
        placeholder,
        label,
        showInput,
        isCoin,
    } = getPlaceholderLabelShow(data)

    const [showModal, setShowModal] = useState(false)
    const [temp, setTemp] = useState(
        data.paymentId
            ? data.paymentId
            : txId
                ? txId
                : ''
    )

    const updatePaymentId = async (e) => {
        e.preventDefault()
        handleGenInfo(i18n.language === 'en'
            ? `You have updated your ${placeholder} to: ${temp}`
            : t('tx_id.updated'))

        if (data) await updateTransactionId(data.id, temp)
    }

    const handleChange = (e) => {
        setTxId(e.target.value)
        setTemp(e.target.value)
    }

    if (
        data.name === 'gumroad' ||
        data.coinName === 'gumroad' ||
        data.name === 'coinz' ||
        data.coinName === 'coinz' ||
        data === 'none' ||
        !data
    ) return null

    return (
        <div className={dark ? `cart_dark` : `txIdContainer`}>
            <form onSubmit={updatePaymentId} style={isCart ? styles.formCart : styles.form}>
                <input
                    onChange={handleChange}
                    name='paymentId'
                    type='text'
                    value={temp}
                    placeholder={label}
                    title={label}
                    style={isCart ? styles.inputCart : styles.input}
                />

                {!isCart &&
                    <button style={styles.button}>
                        {data.paymentId
                            ? `${t('tx_id.update')} ${placeholder}`
                            : `${t('main.submit').toLowerCase()} ${placeholder}`}
                    </button>
                }
            </form>

            {
                showInput &&
                <div className={`helpButtons`}>
                    {isCoin &&
                        <>
                            <button
                                className='infoButton'
                                onClick={() => setShowModal(true)}
                            >{t('tx_id.how')}</button>

                            <Modal
                                onClose={() => setShowModal(false)}
                                show={showModal}
                                title=""
                                buttonText=""
                                link=""
                            >
                                <WhatIsTxId setShowModal={setShowModal} />
                            </Modal>
                        </>
                    }
                </div>
            }
        </div >
    )
}

const styles = {
    form: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        marginRight: isMobile ? 0 : 12,
    },
    formCart: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    input: {
        borderRadius: 5,
        height: 30,
        padding: 5,
        paddingLeft: 10,
        fontSize: '1.1rem',
        flex: 2,
        marginRight: isMobile ? 0 : 10,
        width: isMobile ? '100%' : 'auto',
        marginBottom: isMobile ? 15 : 0,
    },
    inputCart: {
        borderRadius: 5,
        height: 30,
        padding: 5,
        paddingLeft: 10,
        fontSize: '1.1rem',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: isMobile ? '93%' : '98%',
        marginBottom: isMobile ? 15 : 0,
        marginTop: -20,
    },
    button: {
        margin: 0,
        height: 41,
        marginRight: -15,

    }
}

export default TransactionId