import { useRef } from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import { SectionLoader } from '../../Loaders'
import { isPhoto } from './isPhoto'
import { isVideo } from './isVideo'
import '../../../styles/carousel.css'

const Carousel = ({ list = null }) => {
    const { t } = useTranslation()

    const iframeRefs = useRef([])
    const videoRefs = useRef([])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        lazyLoad: true,
        adaptiveHeight: true,
        dotsClass: "slick-dots slick-thumb",
        nextArrow: <></>,
        prevArrow: <></>,
        beforeChange: () => {
            iframeRefs.current.forEach(iframe => {
                if (iframe) {
                    const src = iframe.src
                    iframe.src = ''
                    iframe.src = src
                }
            })
            videoRefs.current.forEach(video => {
                if (video) video.pause()
            })
        },
        customPaging: (i) => {
            if (list[i]) {
                if (isPhoto(list[i])) {
                    return (
                        <img
                            src={list[i]}
                            className="thumbStyle"
                            alt={`img ${i}`}
                            sizes="(max-width: 768px) 100vw,
                            (max-width: 1200px) 100vw,
                            100vw"
                        />
                    )
                } else {
                    return (
                        <img
                            src="https://powershopz.com/images/playButton.png"
                            className="thumbStyle"
                            style={{ objectFit: 'cover' }}
                            alt="video"
                            sizes="(max-width: 768px) 100vw,
                            (max-width: 1200px) 100vw,
                            100vw"
                        />
                    )
                }
            } else {
                return <SectionLoader />
            }
        }
    }

    if (!list || list.length === 0) return <SectionLoader />

    return (
        <div className="carousel_container">
            <Slider {...settings}>
                {list.map((img, index) => {
                    if (isPhoto(img)) {
                        return (
                            <img
                                src={img}
                                alt={img}
                                width={1000}
                                height={1000}
                                loading="lazy"
                            />
                        )
                    } else if (isVideo(img)) {
                        const ext = img.split('.').pop()
                        return (
                            <video
                                key={img}
                                controls
                                width={1000}
                                height={1000}
                                ref={el => videoRefs.current[index] = el}
                            >
                                <source
                                    src={img}
                                    type={`video/${ext}`}
                                />
                                {t('errors.not_supported')}
                            </video>
                        )
                    } else if (img) {
                        return (
                            <iframe
                                key={img}
                                src={img}
                                title={img}
                                width={800}
                                height={400}
                                ref={el => iframeRefs.current[index] = el}
                            />
                        )
                    } else {
                        return null
                    }
                })}
            </Slider>
        </div>
    )
}

export default Carousel
