import { useTranslation } from 'react-i18next'
import '../../styles/cart.css'
import { isMobile } from 'react-device-detect'
import { isBanned } from '../order/banned'

const EmailAddressComment = ({
    email,
    setEmail,
    name,
    setName,
    street,
    setStreet,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    comment,
    setComment,
    setError,
    error,
}) => {
    const { t } = useTranslation()

    const handleEmail = async (email) => {
        setError('')
        await isBanned(email, setEmail)
    }

    return (
        <div className='cart_dvd' id='dvdForm'>
            <h2 style={styles.heading}>
                <span style={styles.number}>1</span>
                {t('cart.shipping_address')}
            </h2>
            <div style={{ height: 15 }}></div>

            <form>
                {/* EMAIL */}
                <input
                    onChange={(e) => handleEmail(e.target.value)}
                    name='email'
                    type='email'
                    className='inputDvd'
                    value={email}
                    required
                    placeholder={t('main.email')}
                    style={error === t('errors.email')
                        ? styles.error
                        : styles.noerror}
                />
                <label htmlFor='email'>
                    <p className='emailCommentLabel'>{t('main.email')}</p>
                </label>

                {/* NAME */}
                <input
                    onChange={(e) => {
                        setError('')
                        setName(e.target.value)
                    }}
                    name='name'
                    type='text'
                    className='inputDvd'
                    value={name}
                    required
                    placeholder={t('main.name')}
                />
                <label htmlFor='name'>
                    <p className='emailCommentLabel'>{t('main.name')}</p>
                </label>

                {/* street */}
                <input
                    onChange={(e) => {
                        setError('')
                        setStreet(e.target.value)
                    }}
                    name='street'
                    type='text'
                    className='inputDvd'
                    value={street}
                    required
                    placeholder={t('main.address')}
                />
                <label htmlFor='street'>
                    <p className='emailCommentLabel'>{t('main.address')}</p>
                </label>

                <div className='cityStateZip'>
                    {/* CITY */}
                    <div style={{ flex: 2, marginRight: 20 }}>
                        <input
                            onChange={(e) => {
                                setError('')
                                setCity(e.target.value)
                            }}
                            name='city'
                            type='text'
                            className='inputDvd'
                            value={city}
                            required
                            placeholder={t('main.city')}
                        />
                        <label htmlFor='city'>
                            <p className='emailCommentLabel'>{t('main.city')}</p>
                        </label>
                    </div>

                    {/* STATE */}
                    <div style={{ flex: 1, marginRight: 22 }}>
                        <input
                            onChange={(e) => {
                                setError('')
                                setState(e.target.value)
                            }}
                            name='state'
                            type='text'
                            className='inputDvd'
                            value={state}
                            required
                            placeholder={t('main.state')}
                        />
                        <label htmlFor='state'>
                            <p className='emailCommentLabel'>{t('main.state')}</p>
                        </label>
                    </div>

                    {/* ZIP */}
                    <div style={{ flex: 1 }}>
                        <input
                            onChange={(e) => {
                                setError('')
                                setZip(e.target.value)
                            }}
                            name='zip'
                            type='text'
                            className='inputDvd'
                            value={zip}
                            required
                            placeholder={t('main.zip')}
                        />
                        <label htmlFor='zip'>
                            <p className='emailCommentLabel'>{t('main.zip')}</p>
                        </label>
                    </div>
                </div>

                <input
                    onChange={(e) => { setComment(e.target.value) }}
                    name='comment'
                    type='text'
                    className='inputDvd'
                    value={comment}
                    placeholder={t('cart.optional')}
                />
                <label htmlFor='comment'>
                    <p className='emailCommentLabel'>{t('cart.comment')}</p>
                </label>
            </form>
        </div>
    )
}

const styles = {
    error: {
        border: '5px solid red',
    },
    noerror: {},
    number: {
        marginRight: 15,
    },
    heading: {
        fontSize: isMobile ? '1.5rem' : '1.8rem',
        marginTop: 0,
    },
}

export default EmailAddressComment