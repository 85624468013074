import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SmallAddToListBtn from '../buttons/SmAddToListBtn'
import '../../styles/cart.css'
import { findProduct } from '../../utils/sql/findProduct'
import { formatPrice } from '../../utils/functions/formatPrice'
import { getLocalStorage, removeItem } from '../../utils/localStorage/functions'

const DvdItem = ({ id, setDvds }) => {
    const { t } = useTranslation()

    const [product, setProduct] = useState({})

    useEffect(() => {
        const getTheProduct = async () => {
            const _product = await findProduct(id)
            setProduct(_product)
        }
        getTheProduct()
    }, [id])

    const deleteProduct = () => {
        removeItem('dvds', id)
        setDvds(getLocalStorage('dvds'))
    }

    const productId = product?.pz_code
    const price = product && formatPrice(product.price)
    const title = product?.title
    const pathId = product?.pz_code

    return !product
        ? null
        : (
            <div
                key={id}
                className='cart_summary'
            >
                <Link
                    className='cart_productName'
                    to={`/${pathId}`}
                >{title}
                </Link>

                <SmallAddToListBtn
                    id={product.id}
                    title={product.title}
                />

                <div className='priceTypeId'>({t('nav.dvds')})</div>
                <div className='priceTypeId'>#{productId}</div>
                <div className='priceTypeId'>${price}</div>
                <button
                    onClick={deleteProduct}>{t('main.delete')}</button>
            </div>
        )
}

export default DvdItem