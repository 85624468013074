import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import '../../styles/cart.css'
import { formatDate } from '../../utils/functions/formatDate'
import { formatPrice } from '../../utils/functions/formatPrice'

const TotalAndOrderNum = ({ data }) => {
    const { t } = useTranslation()
    const date = formatDate(data.created_at)

    return (
        <div className={`totalOrderNumber`} style={styles.main}>

            <div style={styles.flexIt}>
                <h2 style={styles.heading}>{t('main.this_order')}</h2>
                <h3>{date}</h3>
            </div>

            <div style={styles.flexIt}>
                <h3 className={`breakWord`}>
                    <span className={`fade`}>{t('cart.order')}</span>
                    <span style={styles.orderNum}>#{data.orderNumber}</span>
                </h3>

                <h3>
                    <span className={`fade`}>{t('cart.total')}</span>
                    <span style={styles.amount}>
                        ${formatPrice(data.total)}
                    </span>
                </h3>
            </div>
        </div>
    )
}

const styles = {
    main: {
        padding: '0 30px',
    },
    heading: {
        fontSize: '2.5rem',
        marginBottom: 0,
        marginTop: 15,
    },
    flexIt: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    orderNum: {
        marginLeft: 10,
    },
    amount: {
        marginLeft: 10,
    },
}

export default TotalAndOrderNum