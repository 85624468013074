export const generateCode = () => {
    const now = new Date()
    const utcYear = now.getUTCFullYear()
    const utcMonth = String(now.getUTCMonth() + 1).padStart(2, '0')
    const utcDate = String(now.getUTCDate()).padStart(2, '0')
    const utcHour = String(now.getUTCHours()).padStart(2, '0') // Ensure two-digit format
    const dateCode = `${utcYear}-${utcMonth}-${utcDate}-${utcHour}` // UTC Format: YYYY-MM-DD-HH
    const encodedCode = encodeURIComponent(btoa(dateCode))
    return encodedCode
};
