import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
// import { Link } from 'react-router-dom'
import { bubble as Menu } from 'react-burger-menu'
// import logo from '../../images/logo2.png'
import menuItems from './menuItems'
import BurgerMenuItem from './BurgerMenuItem'

// ref: https://www.npmjs.com/package/react-burger-menu

// TODO: https://www.npmjs.com/package/react-contexify

var styles = {
    bmBurgerButton: {
        position: 'fixed',
        width: isMobile ? 25 : 35,
        height: isMobile ? 20 : 27,
        right: isMobile ? 20 : 32,
        top: isMobile ? 28 : 23,
    },
    bmBurgerBars: {
        background: 'snow',
        boxShadow: '1px 2px 3px black',
    },
    bmBurgerBarsHover: {
        background: '#a90000',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px',
        top: 10,
        right: 10,
    },
    bmCross: {
        background: 'gray'
    },
    bmMenuWrap: {
        position: 'fixed',
        top: 75,
    },
    bmMenu: {
        background: 'var(--menuColor)',
        padding: isMobile ? '2em 1.5em 0 1.5em' : '2.5em 2.5em 0 1.5em',
        overflow: 'auto',
        width: '100%',
        borderTop: '1px solid gray'
    },
    bmMorphShape: {
        fill: 'var(--menuColor)',
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        justifyContent: 'space-between',
        height: 'auto',
    },
    bmItem: {
        display: 'block',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        marginTop: 75,
        // marginLeft: -30
    },
    logo: {
        width: 40,
        height: 40,
        borderBottom: '2px solid gray',
        paddingBottom: 15,
        paddingRight: 80,
        marginTop: -35,
        opacity: 0.5,
    },
}

const BurgerMenu = ({ num, listNum }) => {

    const [state, setState] = useState({ menuOpen: false })

    const handleStateChange = (state) => {
        setState({ menuOpen: state.isOpen })
    }

    const closeMenu = () => {
        setState({ menuOpen: false })
    }

    return (
        <div>
            {/* menu */}
            <div className="navbar" id="outer-container">
                <Menu
                    styles={styles}
                    width={270}
                    isOpen={state.menuOpen}
                    onStateChange={(state) => handleStateChange(state)}
                    right
                >
                    <main id="page-wrap">
                        <div>
                            {/* {!isMobile &&
                                <Link to="/" style={{ color: '#bdc3c7' }} onClick={closeMenu}>
                                    <img
                                        src={logo}
                                        id="logo"
                                        style={styles.logo}
                                        alt="Powershotz logo"
                                    />
                                </Link>
                            } */}

                            {menuItems &&
                                menuItems.map((i) => {
                                    return <BurgerMenuItem
                                        item={i}
                                        key={i.label}
                                        num={num}
                                        listNum={listNum}
                                        closeMenu={closeMenu}
                                    />
                                })}

                        </div>
                    </main>
                </Menu>
            </div>
        </div>
    )
}

export default BurgerMenu
