import { useTranslation } from 'react-i18next'

const SortComponent = ({ sortType, handleSort, className }) => {
    const { t } = useTranslation()

    return (
        <select
            className={className}
            onChange={handleSort}
            value={sortType}
        >
            <option value='default'>{t('sort.sort')}</option>
            <option value='priceAsc'>△ {t('sort.price')}</option>
            <option value='priceDesc'>▽ {t('sort.price')}</option>
            <option value='lengthAsc'>△ {t('sort.length')}</option>
            <option value='lengthDesc'>▽ {t('sort.length')}</option>
            <option value='nameAsc'>△ {t('sort.name')}</option>
            <option value='nameDesc'>▽ {t('sort.name')}</option>
        </select>
    )
}

export default SortComponent