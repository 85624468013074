export const getGifData = async (id) => {
    let lcId = id.toString().toLowerCase().replace(/ /g, '')
    if (lcId.includes('bianca')) lcId = 'bianca'
    const _data = { i: lcId }

    const url = `https://data.powershotz.com/php/ggd_p.php`

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_data)
        })

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }

        const data = await response.json()
        const array = []
        data.map(i => {
            if (i.fileName !== '') {
                array.push(i.fileName)
            }
            return true
        })

        return array ? array : []
    } catch (e) {
        console.log(e)
        return false
    }
}