import { useEffect, useState } from 'react'
import { SectionLoader } from './Loaders'
import { useTranslation } from 'react-i18next'

const TimedLoader = (props) => {
    const { t } = useTranslation()

    const [loader, setLoader] = useState(
        <div className={`prod_notFound`}>
            <p>{t('main.loading')}</p>
            <SectionLoader />
        </div>
    )

    const title = props.title
        ? props.title
        : t('main.product_not_found')

    const message = props.message
        ? props.message
        : t('main.refresh_or_check_url')

    useEffect(() => {
        window?.scrollTo(0, 0)

        let timer = setTimeout(() => setLoader(
            <h1 className={`prod_notFound`}>{title}
                <br />
                <span className={`prod_notFoundSmall`}>{message}</span>
                <br />
                {props.children}
            </h1>
        ), 6000)

        return () => clearTimeout(timer)
        // had no dependency array
    }, [message, title, props.children])

    return <div>{loader}</div>
}

export default TimedLoader