import { prevAndNext } from '../../../utils/sql/prevAndNext'

export const getNextPrev = async (product, setPrev, setNext, setPrevTitle, setNextTitle) => {
    if (!product) return

    const data = await prevAndNext(product.title, product.type)
    if (!data) return null

    let prev, next
    if (product.type === 'photos') {
        prev = data?.previous?.title || ''
        next = data?.next?.title || ''
    } else {
        prev = data?.previous?.pz_code || data?.previous?.id || ''
        next = data?.next?.pz_code || data?.next?.id || ''
    }

    setPrev(prev)
    setNext(next)
    setPrevTitle(data?.previous?.title || '')
    setNextTitle(data?.next?.title || '')
};
