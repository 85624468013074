import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AddDvdBtn = ({ id }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClick = () => {
        let ids = JSON.parse(localStorage.getItem('dvds')) || []
        ids.push(id)
        localStorage.setItem('dvds', JSON.stringify([...new Set(ids)]))
        navigate('/dvds')
    }

    return (
        <button
            title={t('main.buy_dvd')}
            onClick={handleClick}
        >{t('main.buy_dvd_btn')} 💿</button>
    )
}

export default AddDvdBtn