import { handleBanned } from '../toast'
import i18n from '../../i18n/i18n'

export const isBanned = async (email, setEmail) => {
    if (!email) return []

    const url = `https://data.powershotz.com/php/be.php?e=${email}`

    const res = await fetch(url)
    const emails = await res.json()

    if (emails.length >= 1) {
        setEmail('')
        handleBanned(i18n.t('main.banned'))
        return false
    } else {
        setEmail(email)
        return true
    }
}