import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RandomGif from './RandomGif'

const NewsAndUpdates = ({ gif, pzcode }) => {
    const { t } = useTranslation()

    return (
        <div className='cardGridNews'>
            <div>
                <h2>{t('home.news')}</h2>
                <p>{t('home.refresh')}</p>
                <h3>{t('home.whats_new')}</h3>
                <ul>
                    <li>{t('home.bug')} <Link to={'/contact'}>{t('nav.contact_us')}</Link>
                    </li>

                    <li>{t('home.safe_sender')}</li>
                </ul>
            </div>
            <RandomGif gif={gif} pzcode={pzcode} />
        </div>
    )
}

export default NewsAndUpdates