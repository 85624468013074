import { useState, useEffect } from 'react'
import 'animate.css'
import isEmail from 'validator/lib/isEmail'
import { isSafari } from 'react-device-detect'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import PowerCoinzDetails from './PowerCoinzDetails'
import { createAccount, getAccount, resetPassword } from './PowerCoinzFunctions'
import { useTranslation } from 'react-i18next'
import { handleError, handleGenInfo } from '../toast'


const PowerCoinzLogin = ({
    setLoggedIn,
    inputs,
    setInputs,
    checked,
    rememberMe,
    thankyou,
    screen,
}) => {
    const { t } = useTranslation()

    const [status, setStatus] = useState(false)
    const [message, setMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    useEffect(() => {
        status && setLoggedIn(true)
    }, [status, setLoggedIn])

    useEffect(() => {
        message.length > 0 && handleGenInfo(message)
    }, [message])

    const handleShowPassword = (e) => {
        e.preventDefault()
        setShowPassword(!showPassword)
    }

    const handleChange = (e) => {
        setMessage('')
        const name = e.target.name
        const value = e.target.value
        setInputs(values => ({
            ...values,
            [name]: value.trim()
        }))
    }

    const handleResetPassword = () => {
        setMessage('')
        inputs.email
            ? resetPassword(inputs.email, setMessage)
            : setMessage(t('coinz.valid_email'))
    }

    const testInputs = () => {
        if (!isEmail(inputs.email)) {
            handleError(t('coinz.valid_email'))
            return false
        } else if (inputs.password.length < 6) {
            handleError(t('coinz.password_length'))
            return false
        } else {
            return true
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setMessage('')
        inputs && testInputs() &&
            await getAccount(inputs, setMessage, setStatus, setInputs)
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        setMessage('')
        inputs && testInputs() &&
            await createAccount(inputs, setMessage, setStatus)
    }

    return (
        <div style={styles.main}>
            {!thankyou && !screen &&
                <PowerCoinzDetails
                    showInfo={showInfo}
                    setShowInfo={setShowInfo}
                />
            }

            <div>
                <h2 style={styles.login} className='dark_coinz'>
                    {thankyou || screen
                        ? t('main.login')
                        : t('coinz.login_create')}
                </h2>

                {!thankyou && !screen &&
                    <p
                        style={styles.same_email}
                        className={isSafari
                            ? "dark_coinz"
                            : "animate__animated animate__pulse animate__repeat-3 dark_coinz"
                        }
                        dangerouslySetInnerHTML={{
                            __html: t('coinz.same_email')
                        }}
                    />
                }

                <form style={styles.form}>

                    <input
                        type='email'
                        name='email'
                        placeholder='Email'
                        title='Email'
                        value={inputs.email}
                        onChange={handleChange}
                        style={styles.input}
                    />

                    <div style={styles.passwordDiv}>
                        <input
                            type={
                                showPassword
                                    ? 'text'
                                    : 'password'
                            }
                            name='password'
                            placeholder='Password'
                            title='Password'
                            value={inputs.password}
                            onChange={handleChange}
                            style={styles.input}
                        />
                        {!showPassword
                            ? <AiOutlineEye
                                style={styles.icon}
                                onClick={handleShowPassword}
                                title='Show password'
                            />
                            : <AiOutlineEyeInvisible
                                style={styles.icon}
                                onClick={handleShowPassword}
                                title='Hide password'
                            />
                        }
                    </div>
                </form>

                <input
                    type='checkbox'
                    onChange={rememberMe}
                    checked={checked}
                />
                <span style={styles.remember} className='dark_coinz'> {t('coinz.save_info')}</span>

                <div>
                    <button
                        type='submit'
                        style={styles.submitButton}
                        onClick={handleLogin}
                    >
                        {t('main.login')}
                    </button>

                    {!thankyou && !screen &&
                        <button
                            type='submit'
                            style={styles.submitButton}
                            onClick={handleCreate}
                        >
                            {t('coinz.create_account')}
                        </button>
                    }
                </div>

                <h4 style={styles.message}>{message}</h4>

                <div onClick={handleResetPassword}>
                    <small style={styles.forgotPassword}>{t('coinz.forgot_password')}</small>
                </div>
            </div>
        </div >
    )
}

const styles = {
    main: {
        margin: 20,
        marginTop: 0,
        textAlign: 'center',
    },
    login: {
        marginBottom: 8,
        fontWeight: 'bold'
    },
    same_email: {
        fontSize: '0.9rem',
        marginTop: 5,
        marginBottom: 30
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        marginBottom: 10,
        width: 250,
        height: 25,
        paddingLeft: 10,
    },
    remember: {
    },
    icon: {
        transform: 'translateY(8px) translateX(-20px)',
        cursor: 'pointer',
    },
    passwordDiv: {
        display: 'flex',
        flexDirection: 'row',
        transform: 'translateX(7px)'
    },
    submitButton: {
        marginTop: 10,
        transform: 'scale(0.8)'
    },
    message: {
        color: 'red',
    },
    forgotPassword: {
        textDecorationLine: 'underline',
        color: 'blue',
        cursor: 'pointer',
    },
    emphasis: {
        // fontSize: '1rem',
        fontWeight: 'bold',
    },
}

export default PowerCoinzLogin