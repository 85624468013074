import { useTranslation } from 'react-i18next'
import getCCPaymentLink from './getCCPaymentLink'
import { useEffect, useState } from 'react'
import { SectionLoader } from '../Loaders'
import { isMobile } from 'react-device-detect'
import EnterEmailToPay from './EnterEmailToPay'

const CreditCardFrame = ({
    order,
    isVerified,
    setIsVerified
}) => {
    const { t } = useTranslation()

    const [addy, setAddy] = useState()
    const [coinPrice, setCoinPrice] = useState()

    useEffect(() => {
        getCCPaymentLink(order.total, setAddy)
        setCoinPrice(order.total)
    }, [order])

    const handleClick = () => {
        alert(t('main.why_cc_text'))
    }

    if (!order) return <SectionLoader />

    if (!isVerified) {
        return (
            <EnterEmailToPay
                order={order}
                setIsVerified={setIsVerified}
            />
        )
    }

    return (
        <div>
            <h2>How To Pay:</h2>
            <ul style={styles.ul}>
                <li
                    dangerouslySetInnerHTML={{
                        __html: t('payment.donation_link')
                    }}
                />
                <li
                    dangerouslySetInnerHTML={{
                        __html: t('coinz.same_email_everywhere')
                    }}
                />
                <li style={styles.li}
                    dangerouslySetInnerHTML={{
                        __html: t('payment.exact_amount')
                    }}
                />
                <li style={styles.li}
                    dangerouslySetInnerHTML={{
                        __html: t('coinz.use_vpn')
                    }}
                />

                <li style={styles.li}>{t('payment.come_back')}</li>

                <li style={styles.li}
                    dangerouslySetInnerHTML={{
                        __html: t('payment.gumroad_message')
                    }}
                />
            </ul>

            <div style={{ textAlign: 'center' }}>
                <button style={{ margin: 0, marginBottom: 15 }} onClick={handleClick}>
                    {t('main.why_cc')}
                </button>
            </div>

            <p
                className={`cart_fade`}
                style={styles.send}>
                {t('payment.pay')}
            </p>

            <p style={styles.price}>
                ${coinPrice} <span style={styles.small}>USD</span>
            </p>

            <div style={styles.iframe}>
                <span>{t('main.scroll_window')}</span>
                <iframe
                    src={addy}
                    title='payment'
                    referrerPolicy="no-referrer"
                    height='100%'
                    width='100%'
                // sandbox="allow-scripts"
                ></iframe>
            </div>
            <p style={styles.message}>{t('payment.come_back')}</p>
        </div>
    )
}

const styles = {
    iframe: {
        transform: 'scale(0.95)',
        height: 500,
        margin: 'auto',
        marginTop: 10,
        marginBottom: 50,
        textAlign: 'center',
        maxWidth: 750,
    },
    send: {
        textAlign: 'center',
    },
    price: {
        textAlign: 'center',
        color: 'red',
        fontSize: '2.5rem',
        margin: 0,
        fontWeight: 'bold',
    },
    small: {
        fontSize: '1.25rem',
    },
    message: {
        fontSize: isMobile ? '1.25rem' : '1.5rem',
        textAlign: 'center',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    ul: {
        lineHeight: 1.75,
        paddingLeft: 15,
    },
}

export default CreditCardFrame