export const didCustomerOrder = async (email, setPrevCustomer) => {
    if (!email) return []

    const url = `https://data.powershotz.com/php/dco.php?e=${email}`

    const url_ok = `https://data.powershotz.com/php/ae.php?e=${email}`

    const url_coinz = `https://data.powershotz.com/php/ce.php?e=${email}`

    const res = await fetch(url)
    const orders = await res.json()

    const res_ok = await fetch(url_ok)
    const emails = await res_ok.json()

    const res_coinz = await fetch(url_coinz)
    const coinz = await res_coinz.json()

    orders.length >= 1 || emails.length >= 1 || coinz.length >= 1
        ? setPrevCustomer(true)
        : setPrevCustomer(false)
}
