import { searchProducts } from '../../comps/search/searchProducts'

export const sortProducts = async (method, searchPhrase) => {
    if (searchPhrase.toLowerCase() === 'kristalee') return null

    const sortOptions = {
        priceAsc: { orderBy: 'price', direction: 'ASC' },
        priceDesc: { orderBy: 'price', direction: 'DESC' },
        lengthAsc: { orderBy: 'length', direction: 'ASC' },
        lengthDesc: { orderBy: 'length', direction: 'DESC' },
        nameAsc: { orderBy: 'title', direction: 'ASC' },
        nameDesc: { orderBy: 'title', direction: 'DESC' },
    }

    const { orderBy = 'title', direction = 'ASC' } = sortOptions[method] || {}
    return await searchProducts(searchPhrase, orderBy, direction)
}
