import { useTranslation } from 'react-i18next'
import PowershopzHeader from './PowershopzHeader'

const CastingCalls = ({ castingCalls }) => {
    const { t } = useTranslation()

    const castingCallDescription = (desc) => {
        if (desc.includes('powershotz.com/application')) {
            const newDesc = desc.replace('powershotz.com/application.', '')
            return { desc: newDesc, link: true }
        } else {
            return { desc: desc, link: false }
        }
    }

    return (
        <div className='cardGridCasting' id='castingCalls'>

            <PowershopzHeader title={t('home.casting_calls')} />

            <div className='powershopzSectionCasting'>
                {castingCalls &&
                    castingCalls.map((call) => {
                        // Split timestamp into [ Y, M, D, h, m, s ]
                        const t = call.date.split(/[- :]/)

                        // Apply each element to the Date function
                        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]))

                        // readable format 'Apr 9, 2022'
                        const x = d.toString().split(' ')
                        const date = x[1] + ' ' + x[2] + ', ' + x[3]

                        const { desc, link } = castingCallDescription(call.description)

                        return (
                            <div
                                className='castingCallMap'
                                key={call.id}
                            >
                                <p className='fullWidth'>
                                    <img
                                        src={call.image}
                                        alt={call.studio}
                                        className='callImg'
                                        width={100}
                                        height={100}
                                    />
                                    <b className='callHeading'>{call.heading}</b>
                                    <br /><br />{desc} {link}</p>

                                <p><small>contact:</small> <b>{call.studio}</b>
                                    <br />
                                    <a href={`mailto:${call.email}?subject=Casting Call`}>{call.email}</a></p>
                                <p className='castingCallDate'>{date}</p>
                                <hr />
                            </div>)
                    })}
            </div>
        </div>
    )
}

export default CastingCalls