export const searchProducts = async (searchTerm, orderBy = 'title', direction = 'ASC') => {
    if (!searchTerm) return []

    const url = `https://data.powershotz.com/php/sp_p.php`
    const data = {
        st: searchTerm,
        ob: orderBy,
        d: direction,
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }

        const result = await response.json()
        return result

    } catch (error) {
        console.error('Error:', error.message)
        return []
    }
}
