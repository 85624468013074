import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { IoMdCloseCircleOutline } from "react-icons/io"

const PowerCoinzIframe = ({
    coin,
    thankyou,
    setSeeBuy,
    screen
}) => {
    const { t } = useTranslation()

    const handleClick = () => {
        setSeeBuy(false)
    }

    return (
        <div style={styles.main}>
            <ul style={styles.list}>
                <li style={styles.li}> {t('coinz.iframe_1')}</li>
                <li style={styles.li}
                    dangerouslySetInnerHTML={{
                        __html: t('coinz.iframe_2')
                    }}
                />

                <li style={styles.li}
                    dangerouslySetInnerHTML={{
                        __html: t('coinz.iframe_3')
                    }}
                />

                <li style={styles.li}
                    dangerouslySetInnerHTML={{
                        __html: t('coinz.no_comment')
                    }}
                />

                {!thankyou && !screen &&
                    <li style={styles.li}
                        dangerouslySetInnerHTML={{
                            __html: t('coinz.iframe_4')
                        }}
                    />
                }
            </ul>

            <div style={styles.iframe}>
                <IoMdCloseCircleOutline
                    size={isMobile ? 35 : 40}
                    style={styles.icon}
                    onClick={handleClick}
                    title={t('modals.close')}
                />
                <iframe
                    src={coin.address}
                    title='payment'
                    referrerPolicy="no-referrer"
                    height='100%'
                    width='100%'
                // sandbox="allow-scripts"
                ></iframe>
            </div>
        </div>
    )
}

const styles = {
    main: {
        marginTop: 20,
    },
    iframe: {
        transform: isMobile
            ? 'scale(0.9) translateY(-20px)'
            : 'scale(0.78) translateY(-70px)',
        height: isMobile ? 550 : 600,
        marginBottom: -30,
        paddingBottom: isMobile ? 30 : 0
    },
    list: {
        textAlign: 'left',
        marginLeft: isMobile ? 0 : '10%',
        marginRight: isMobile ? '5%' : '10%',
    },
    li: {
    },
    button: {
        marginTop: 8,
    },
    icon: {
        transform: 'translateY(40px)',
        position: 'fixed',
        top: -20,
        right: 10,
        cursor: 'pointer',
    },
}

export default PowerCoinzIframe