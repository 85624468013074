export const placeOrder = async (order) => {
    const url = 'https://data.powershotz.com/php/po2.php'
    const data = { order }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const result = await response.json()
        return result
    } catch (e) {
        console.log(`Error placing order: ${e.message}`)
        return null
    }
}
