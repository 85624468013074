import { useNavigate } from 'react-router-dom'
import { GiCompactDisc } from "react-icons/gi"
import { useTranslation } from 'react-i18next'
import '../../styles/products.css'

const SmAddDvdBtn = ({ id }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClick = () => {
        let ids = JSON.parse(localStorage.getItem('dvds')) || []
        ids.push(id)
        localStorage.setItem('dvds', JSON.stringify([...new Set(ids)]))
        navigate('/dvds')
    }

    return (
        <span>
            <GiCompactDisc
                className='prod_dvdIcon'
                size={25}
                title={t('main.buy_dvd')}
                onClick={handleClick}
            />
        </span>
    )
}

export default SmAddDvdBtn