import { handleError, handleGenInfo } from '../toast'
import i18n from '../../i18n/i18n'
import { formatPrice } from '../../utils/functions/formatPrice'

export const emailCoinzOrder = async (info) => {
    if (!info || !info.coin || !info.coin.name || info.coin.name !== 'coinz') return Promise.resolve()

    console.log('email coinz order:', info)

    const url = `https://data.powershotz.com/php/pc/eco.php`
    const data = { orderNumber: info.orderNumber }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }
    } catch (error) {
        console.error('Error:', error)
    }
}

export const createAccount = async (data, setMessage, setStatus) => {
    const url_get = `https://data.powershotz.com/php/pc/fe.php?email=${data.email}`

    fetch(url_get)
        .then((res) => res.json())
        .then((res) => {
            if (Object.is(res, null)) {
                // if no account exists create it
                addToDatabase(data, setMessage, setStatus)
            } else {
                // if account exists return false
                setMessage(i18n.t('coinz.email_exists'))
                setStatus(false)
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`${i18n.t('coinz.error_checking_account')}\n${e.message}`)
            setMessage(i18n.t('coinz.server_error'))
            setStatus(false)
        })
}

const addToDatabase = async (data, setMessage, setStatus) => {
    const url_create = 'https://data.powershotz.com/php/pc/crea.php'

    fetch(url_create, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) {
                setStatus(true)
                setMessage(i18n.t('coinz.new_account'))
            } else {
                setStatus(false)
                setMessage(i18n.t('coinz.server_error'))
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`Error creating PowerCoinz account\n${e.message}`)
            setStatus(false)
            setMessage(i18n.t('coinz.server_error'))
        })
}

export const getAccount = async (data, setMessage, setStatus, setInputs) => {
    const url_get = `https://data.powershotz.com/php/pc/ga.php?email=${data.email}&password=${data.password}`


    fetch(url_get)
        .then((res) => res.json())
        .then((res) => {
            if (res === null) {
                // if no account throw error
                setMessage(i18n.t('coinz.login_error'))
                setStatus(false)
            } else {
                // if account exists return true
                setMessage(`${i18n.t('coinz.successful_login')} ${formatPrice(parseFloat(res.balance))}`)
                setStatus(true)
                setInputs(values => ({
                    ...values,
                    balance: res.balance
                }))
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`${i18n.t('coinz.server_error')}\n${e.message}`)
            setMessage(i18n.t('coinz.server_error'))
            setStatus(false)
        })
}

export const debitBalance = async (info) => {
    if (!info || !info.coin || !info.coin.name || info.coin.name !== 'coinz') return Promise.resolve()

    const coin = info.coin
    const url_create = 'https://data.powershotz.com/php/pc/de.php'
    const data = {
        email: coin.email,
        password: coin.password,
        amount: info.total,
        referenceNumber: info.orderNumber,
    }

    return fetch(url_create, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) {
                handleGenInfo(`${i18n.t('coinz.account_debit')} $${parseFloat(info.total).toFixed(2)}`)
            } else {
                handleError('Error')
                alert(i18n.t('coinz.error_debit'))
                throw new Error('Debit failed')
            }
        })
        .catch((e) => {
            console.log(`Error debiting PowerCoinz account\n${e.message}`)
            handleError(i18n.t('coinz.server_error'))
            alert(i18n.t('coinz.server_error'))
            throw e
        })
}


export const resetPassword = (email, setMessage) => {
    const url = `https://data.powershotz.com/php/pc/epr.php?email=${email}`

    fetch(url)
        .then((res) => {
            if (res.status === 200) {
                setMessage(`${i18n.t('coinz.email_sent')} ${email}`)
            } else {
                setMessage(`${i18n.t('coinz.email_none')} ${email}.`)
            }
        })
}
