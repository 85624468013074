const getCCPaymentLink = async (totalAmount, setAddy) => {
    const url = `https://data.powershotz.com/php/gccl.php?a=${totalAmount}`

    try {
        const response = await fetch(url)

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        const link = data.find(i => i.link.includes('lemonsqueezy'))?.link

        setAddy(link || 'https://designtech1.lemonsqueezy.com/buy/956a0e60-9621-4024-8d22-aacc733ecf85?media=0&logo=0&desc=0&discount=0')
    } catch (e) {
        console.error(e.message)
        setAddy('https://designtech1.lemonsqueezy.com/buy/956a0e60-9621-4024-8d22-aacc733ecf85?media=0&logo=0&desc=0&discount=0')
    }
}

export default getCCPaymentLink
