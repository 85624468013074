import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import CopyComp from '../CopyComp'
import TransactionId from './TransactionId'
import { fetchCoinPrice } from './fetchCoinPrice'
import '../../styles/cart.css'

const Payment = ({
    coin,
    coinPrice,
    setCoinPrice,
    total,
    txId,
    setTxId,
}) => {
    const { t } = useTranslation()

    const [abbr, setAbbr] = useState(coin && coin.value)
    const [src, setSrc] = useState(
        coin &&
            coin.value
            ? coin.value.toLowerCase()
            : 'https://data.powershotz.com/crypto.png')

    useEffect(() => {
        if (coin) {
            coin.name && fetchCoinPrice(coin.name, setCoinPrice, total)
            if (coin.value === 'USD') {
                setAbbr('')
                setSrc('invoice')
            } else if (coin.value === 'ZELLE') {
                setAbbr('')
                setSrc('zelle')
            } else if (coin.value === 'CASHAPP') {
                setAbbr('')
                setSrc('cashApp')
            } else if (coin.value === 'gumroad') {
                setAbbr('')
                setSrc('cc')
            } else {
                setAbbr(coin.value)
                setSrc(null)
            }
        }
    }, [coin, setCoinPrice, total])

    let coinAddress = coin && coin.address
    if (coin && coin.address === 'zelle@powershopz.com') {
        coinAddress = 'zelle@powershotz.com'
    }
    if (coin && coin.value === 'USD') {
        coinAddress = <span>DesignTech<br />PO Box 141<br />Wildwood, MO 63038 USA</span>
    }

    const imgUrl = coin.value === 'ZELLE'
        ? null : `https://powershopz.com/altcoin/${src}.png`

    return coin && (
        <div style={styles.main}>
            <div style={styles.directionsImage}>
                <div style={styles.copyCompContainer}>
                    <div style={styles.copyComp}>
                        <p style={styles.direction}>
                            {t('payment.send')}
                        </p>

                        <CopyComp
                            text={`${coinPrice} ${abbr}`}
                            textToCopy={coinPrice}
                        />
                    </div>

                    <div style={styles.copyComp}>
                        <p style={styles.direction}>
                            {t('payment.to')}
                        </p>

                        <CopyComp
                            text={coinAddress}
                            textToCopy={coin.address === 'zelle@powershopz.com' ? 'zelle@powershotz.com' : coin.address}
                        />
                    </div>
                </div>

                {coin.value !== 'gumroad' &&
                    coin.value !== 'USD' &&
                    imgUrl
                    ? <img
                        src={imgUrl}
                        width={120}
                        height={120}
                        alt={abbr ? abbr : ''}
                    />
                    : <div style={{ height: coin.value === 'gumroad' ? 30 : 0 }}></div>
                }
            </div>

            <TransactionId
                data={coin}
                txId={txId}
                setTxId={setTxId}
            />
        </div >
    )
}

const styles = {
    main: {
        padding: isMobile ? '10px 20px ' : '20px 30px',
        borderTop: '1px solid gray',
        marginTop: 0,
    },
    copyCompContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        minHeight: 120,
    },
    copyComp: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    direction: {
        fontSize: isMobile ? '1.4rem' : '1.5rem',
        margin: 0,
        marginRight: 10,
        color: 'crimson',
        fontWeight: 'bold'
    },
    directionsImage: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}

export default Payment