import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCartPlusFill, BsCartDash } from 'react-icons/bs'
import { handleAddToCart, handleRemoveFromToCart } from '../toast'
import { addItem, isInCart, removeItem } from '../../utils/localStorage/functions'

const inCartIcon = <BsCartDash
    size={14}
/>
const notInCartIcon = <BsCartPlusFill
    size={14}
/>

const AddToCartBtn = ({ id, title, action }) => {
    const { t } = useTranslation()
    const [inCart, setInCart] = useState(false)

    useEffect(() => {
        setInCart(isInCart(id))
    }, [id, action])

    const handleClick = () => {
        if (isInCart(id)) {
            handleRemoveFromToCart(title)
            removeItem('cart', id, setInCart)
        } else {
            handleAddToCart(title)
            addItem('cart', id, setInCart)
        }
    }

    const titleMessage = inCart
        ? t('main.remove_from_cart')
        : t('main.add_to_cart')

    const message = inCart
        ? <span>{t('main.remove_from_cart')} {inCartIcon}</span>
        : <span>{t('main.add_to_cart')} {notInCartIcon}</span>

    return (
        <>
            <button
                title={titleMessage}
                onClick={handleClick}
            >{message}</button>

            {inCart
                ? <BsCartDash
                    className={`prod_cartIcon`}
                    title={titleMessage}
                    onClick={handleClick}
                    size={29}
                />
                : <BsCartPlusFill
                    className={`prod_cartIcon`}
                    title={titleMessage}
                    onClick={handleClick}
                    size={29}
                />
            }
        </>
    )
}

export default AddToCartBtn