import { useTranslation } from 'react-i18next'

const FasterProcessing = ({ setShowModal }) => {
    const { t } = useTranslation()

    return (
        <div onClick={() => setShowModal(false)}>
            <p>{t('faster.p1')}</p>

            <p>{t('faster.p2')}</p>

            <small>{t('faster.p3')}</small>
            <br />

            <small>{t('faster.p4')}</small>
        </div>
    )
}

export default FasterProcessing