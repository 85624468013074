import { useEffect, useState } from 'react'
import { handleAddToCart, handleRemoveFromToCart } from '../toast'
import { addItem, isInCart, removeItem } from '../../utils/localStorage/functions'
import { BsCartPlusFill, BsCartDash } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

const style = {
    transform: 'translateY(1px)',
}

const inCartIcon = <BsCartDash
    size={14}
    style={style}
/>
const notInCartIcon = <BsCartPlusFill
    size={14}
    style={style}
/>

const AddToCartBtnNoIcon = ({ id, title }) => {
    const { t } = useTranslation()
    const [inCart, setInCart] = useState(false)

    useEffect(() => {
        setInCart(isInCart(id))
    }, [id])

    const handleClick = () => {
        if (isInCart(id)) {
            handleRemoveFromToCart(title)
            removeItem('cart', id, setInCart)
        } else {
            handleAddToCart(title)
            addItem('cart', id, setInCart)
        }
    }

    const message = inCart
        ? <span>{t('main.remove_from_cart')} {inCartIcon}</span>
        : <span>{t('main.add_to_cart')} {notInCartIcon}</span>
    const titleMessage = inCart
        ? t('main.remove_from_cart')
        : t('main.add_to_cart')

    return (
        <button
            title={titleMessage}
            onClick={handleClick}
        >{message}</button>
    )
}

export default AddToCartBtnNoIcon