import { isMobile, isSafari } from 'react-device-detect'
import 'animate.css'
import { useTranslation } from 'react-i18next'
import { IoMdCloseCircleOutline } from "react-icons/io"

const PowerCoinzDetails = ({ showInfo, setShowInfo }) => {
    const { t } = useTranslation()

    const handleToggle = () => setShowInfo(!showInfo)

    return (
        <div className='dark_coinz'>
            <div style={styles.coinzBtnContainer}>
                {!showInfo &&
                    <button
                        onClick={handleToggle}
                        style={styles.coinzButton}
                    >
                        {t('coinz.what_are')}
                    </button>
                }
            </div>

            {showInfo &&
                <div style={styles.info}>
                    <IoMdCloseCircleOutline
                        size={30}
                        title={t('modals.close')}
                        style={{
                            float: 'right',
                            marginRight: -20,
                            marginTop: 5,
                            cursor: 'pointer'
                        }}
                        onClick={handleToggle}
                    />

                    <h3>{t('coinz.what_are')}</h3>
                    <ul>
                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.what_are_li_1')
                            }}
                        />

                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.what_are_li_2')
                            }}
                        />
                    </ul>

                    <h3>{t('coinz.how_to_buy')}</h3>
                    <ul>
                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.how_to_buy_li_1')
                            }}
                        />
                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.how_to_buy_li_2')
                            }}
                        />
                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.how_to_buy_li_3')
                            }}
                        />
                    </ul>

                    <h3>{t('coinz.why_use')}</h3>
                    <ul>
                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.why_use_li_1')
                            }}
                        />

                        <li
                            style={styles.li}
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.why_use_li_2')
                            }}
                        />

                        <li
                            style={styles.li}
                            className={
                                isSafari
                                    ? ""
                                    : "animate__animated animate__pulse animate__repeat-3 animate__delay-5s"
                            }
                            dangerouslySetInnerHTML={{
                                __html: t('coinz.why_use_li_3')
                            }}
                        />
                    </ul>


                    <h3 style={styles.summary}>{t('coinz.details_summary')}</h3>

                    <div style={styles.coinzButtonSmall}>
                        <button
                            onClick={() => setShowInfo(false)}
                            style={styles.coinzButton}
                        >
                            {t('coinz.close_details')}
                        </button>
                    </div>
                </div>
            }

        </div>
    )
}

const styles = {
    coinzBtnContainer: {
        textAlign: 'center',
        margin: 0,
        marginBottom: 30,
    },
    coinzButton: {
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: 'rgb(52, 152, 219)',
        cursor: 'pointer',
        color: 'white',
    },
    coinzButtonSmall: {
        width: '100%',
        textAlign: 'center',
        transform: 'scale(0.8)'
    },
    info: {
        backgroundColor: '#e9e9ed',
        padding: isMobile ? 20 : 30,
        paddingTop: 8,
        paddingBottom: 20,
        borderRadius: 5,
        marginTop: -20,
        textAlign: 'left',
    },
    li: {
    },
    summary: {
        marginTop: 30,
        textAlign: 'center',
        fontWeight: 'bold',
    },
}

export default PowerCoinzDetails