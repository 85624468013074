import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/products.css'

const TitleDescriptionSection = memo(({ product }) => {
    const { t } = useTranslation()

    const shopz = parseInt(product.shopz) === 1 ? true : false
    const productId = product.title.replace(/'/g, '')
    const type = product.name ? product.name.split('.').pop() : 'zip'

    return (
        <div className={`prod_titleDescSection`}>

            <h1 className={`prod_title2`}>{product.title}</h1>

            <p className='prod_type'>({
                product.type === 'clip'
                    ? t('main.video')
                    : t('main.photos')
            })</p>

            <p className={`prod_description`}>{product.description}</p>

            <div className={`prod_formatShopzLink`}>
                <p className={`prod_format`}>{t('main.format')}: {type}</p>
                {shopz &&
                    <p className={`prod_format`}>
                        <a
                            href={`https://powershopz.com/Powershotz/${productId}`}
                            target="_blank"
                            rel="noreferrer"
                            title={t('main.available_shopz')}
                            className={`prod_shopzLogo`}
                        >
                            <img className={`prod_shopzImage`} alt='Powershopz' />
                            {t('main.available_shopz')}
                        </a>
                    </p>
                }
            </div>

        </div>
    )
})

export default TitleDescriptionSection