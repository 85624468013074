import { useEffect, useState } from 'react'
import Card from './Card'
import CardFav from './CardFav'
import '../../styles/products.css'
import { findProduct } from '../../utils/sql/findProduct'

const CardId = ({ id, fav = false }) => {
    const [data, setData] = useState()

    useEffect(() => {
        const test = async () => {
            const prod = await findProduct(adjustId)
            setData(prod)
        }
        const adjustId = id.replace(' & ', '%20and%20')
        test()
    }, [id])

    if (!data) return null

    return fav
        ? <CardFav product={data} />
        : <Card product={data} />

}

export default CardId