import { placeOrder } from '../../utils/sql/placeOrder'
import { keepCheckedItems } from '../../utils/localStorage/functions'
import { debitBalance, emailCoinzOrder } from '../payments/PowerCoinzFunctions'

const clearCart = () => {
    sessionStorage.removeItem('order')
    keepCheckedItems('cart')
}

export const runPlaceOrder = async (data) => {
    try {
        await placeOrder(data)
        console.log('Order placed')

        await debitBalance(data)
        console.log('Balance debited')

        await emailCoinzOrder(data)
        console.log('email coinz order:', data.orderNumber)

        clearCart()
        console.log('Cart cleared')

        sessionStorage.setItem('orderNumber', data.orderNumber)
        sessionStorage.setItem('temp_email', data.email)
        localStorage.removeItem('cartOrderNumber')
        console.log('Session and local storage updated')

        const orderNumber = data.orderNumber
        console.log(`Navigating to /order/${orderNumber}`)
        window.location.replace(`/order/${orderNumber}`)
    } catch (e) {
        console.log(e)
    }
}
