export const prevAndNext = async (title, type) => {
    const url = 'https://data.powershotz.com/php/pn.php'
    const data = { title, type }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`)
        }

        const result = await response.json()
        return result
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}