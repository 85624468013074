import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import { SectionLoader } from '../Loaders'
import OtherOrderItem from './OtherOrderItem'
import '../../styles/cart.css'
import { fetcher } from '../../utils/functions/fetcher'
import { useEffect, useState } from 'react'

const OtherOrders = ({ thisOrderNumber }) => {
    const { t } = useTranslation()

    const [email, setEmail] = useState('')

    useEffect(() => {
        const url = `https://data.powershotz.com/php/ve.php?on=${thisOrderNumber}`

        fetch(url)
            .then(res => res.json())
            .then(data => {
                const _orderEmail = data.email.toLowerCase().trim()
                setEmail(_orderEmail)
            })
    }, [thisOrderNumber])


    const url = `https://data.powershotz.com/php/gcon.php?e=${email}`

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error) return <div className={`card`}>{t('main.error')}</div>
    if (isLoading) return <div className={`main`}><SectionLoader /></div>
    if (!data || data.length === 1) return null

    return (
        <div className={`cartItemsContainer`} style={styles.main}>
            <h2 className={`containerHeading`} style={styles.heading}>{t('order.order_history')}</h2>

            {data.map(i => {
                if (i.orderNumber !== thisOrderNumber) {
                    return (
                        <OtherOrderItem
                            key={i.orderNumber}
                            item={i}
                            thisOrderNumber={thisOrderNumber}
                        />
                    )
                } else {
                    return null
                }
            })}

        </div>
    )
}

const styles = {
    main: {
        marginTop: 76,
    },
    heading: {
        fontSize: '2.5rem'
    }
}

export default OtherOrders