import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StarsIn from '../comps/detail/StarsIn'
import TimedLoader from '../comps/TimedLoader'
import ModelVideos from '../comps/detail/ModelVideos'
import InfoSection from '../comps/detail/InfoSection'
import Carousel from '../comps/detail/Carousel/Carousel'
import ButtonSection from '../comps/detail/ButtonSection'
import { getNextPrev } from '../comps/detail/functions/getNextPrev'
import { setProductData } from '../comps/detail/functions/getProductData'
import TitleDescriptionSection from '../comps/detail/TitleDescriptionSection'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/products.css'

const Detail = () => {
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const [product, setProduct] = useState(null)
    const [mediaList, setMediaList] = useState(null)
    const [imageLink, setImageLink] = useState('')
    const [starsInData, setStarsInData] = useState(null)
    const [prev, setPrev] = useState()
    const [next, setNext] = useState()
    const [prevTitle, setPrevTitle] = useState()
    const [nextTitle, setNextTitle] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)  // Debounce or throttle this if needed.
    }, [pathname])

    useEffect(() => {
        setLoading(true)
        setProduct(null)
        setProductData(pathname, setProduct, setMediaList, setImageLink, setStarsInData).finally(() => setLoading(false))
    }, [pathname])

    useEffect(() => {
        getNextPrev(product, setPrev, setNext, setPrevTitle, setNextTitle)
    }, [product])

    return loading || !product ? (
        <TimedLoader />
    ) : (
        <div>
            <MetaDecorator
                title={product.title}
                keywords={product.description.split(" ")}
                image={imageLink}
                description={product.description}
                type="product"
                url={`https://powershotz.com/${pathname}`}
                price={product.price}
            />

            <div id="prod">
                <div className="prod_productContainer">
                    <div className="prod_titlePrevNext">
                        {prev && (
                            <Link
                                title={prevTitle}
                                to={`/${prev}`}
                                className="prod_prevNext"
                            >
                                {t('main.prev')}
                            </Link>
                        )}

                        <h1 className="prod_title2">{product.title}</h1>

                        {next && (
                            <Link
                                title={nextTitle}
                                to={`/${next}`}
                                className="prod_prevNext"
                            >
                                {t('main.next')}
                            </Link>
                        )}
                    </div>

                    <Carousel list={mediaList} key={pathname} />

                    <TitleDescriptionSection product={product} />

                    <InfoSection product={product} />

                    <ButtonSection product={product} starsIn={starsInData} />

                    {product.type === 'clip' ? (
                        <StarsIn product={product} starsIn={starsInData} />
                    ) : (
                        <ModelVideos product={product} starsIn={starsInData} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Detail
